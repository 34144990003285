<template>
  <h1>Your personal data not related to one server.</h1>
<transscript-component/>
</template>

<script>
import TransscriptComponent from "@/components/transscriptComponent";
export default {
  name: "UserView",
  components: {TransscriptComponent},
}
</script>

<style scoped>

</style>